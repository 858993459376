<template>
  <div class="mod-home">
    <div class="home-title">
      <div class="text-title" style="">健康小屋</div>
      <div class="text-home">
        <span class="text-home-sub1">
          心狗理念
        </span>
        <span class="text-home-sub2">
          健康中国——为百姓健康保驾护航
        </span>
      </div>

    </div>

    <br/>
    <p style="font-size: 25px; font-weight: bold;">尊敬的 {{Uname}} 管理者:</p>
    <p style="font-size: 25px; text-indent: 50px">
      您好,欢迎来到心狗健康管理系统-健康小屋平台！
      您上一次登录时间是{{lastlogin_process}}。
    </p>
    <br>
    <br>
    <p style="font-size: 20px; font-weight: bold;">最新动态：</p>
    <p style="font-size: 20px; text-indent: 50px">
      注册总人数：<el-tag  effect="dark" style="font-size: 20px;">{{registerCount}} 人</el-tag>
    </p>



  </div>
</template>

<script>
export default {
  name: 'home',
  data () {
    return {
      registerCount:'3',
      last_login:'',
      Uname:'',
      lastlogin_process:'',

    }
  },
  created () {
    console.log(this.last_login)
    this.Uname = this.$store.state.health_station.ename
  },
  mounted(){
    this.lastLoginProcess()
    this.getUserNum()
  },

  methods: {
    lastLoginProcess() {
      // 修改了上次登录时间差8小时的问题
      var tempdate = new Date(this.$store.state.health_station.last_login)
      var lastlogin = tempdate
      lastlogin.setHours(tempdate.getHours() + 8)
      let y = lastlogin.getFullYear();
      let MM = lastlogin.getMonth() + 1;
      MM = MM < 10 ? ('0' + MM) : MM;
      let d = lastlogin.getDate();
      d = d < 10 ? ('0' + d) : d;
      let h = lastlogin.getHours();
      h = h < 10 ? ('0' + h) : h;
      let m = lastlogin.getMinutes();
      m = m < 10 ? ('0' + m) : m;
      let s = lastlogin.getSeconds();
      s = s < 10 ? ('0' + s) : s;
      this.lastlogin_process = y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;  //显示到秒
    },
    getUserNum() {
      this.$http({
        url: this.$http.adornUrl('/enterprise/enterpriseuserinfo/getUserNum'),
        method: 'get',
        params: this.$http.adornParams({
          eid: this.$store.state.health_station.eid,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data)
          this.registerCount  = data.userNum
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    }
  },


}
</script>

<style lang="scss" scoped>
.mod-home {
  line-height: 1;
}
.home-title {
  background-image: linear-gradient(45deg, #830707, #b30808);
  padding-top:15px;
  padding-bottom:15px;
  .text-title{
    font-size: 40px;
    text-align: center;
    color: #ffffff;
    padding-bottom: 20px;
    font-weight: bold;
  }
  .text-home {
    text-align: center;
    font-size: 28px;
    color: rgb(255, 255, 255);
    .text-home-sub1{
      color: #830707;
      background-color: #ffffff;
      font-weight: bold;
      border-radius: 4px;
      padding-left: 5px;
    }
    .text-home-sub2{
      padding-left: 15px;
    }
  }
}
.data-item{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;

  :hover {
    background-color: #8dc0bb;
    text-decoration: none;
  }
  .item {
    padding: 5px;
    // padding-left: 10px;
    margin: 10px;
    // margin-left: 10px;
    width: 20%;
    height: 50px;
    line-height: 40px;
    // flex-wrap: nowrap;
    text-align: center;
    font-weight: bold;
    .title{
      font-size: 24px;
      height: 100%;
      color: rgb(255, 255, 255);
      background-color: #464545;
      width: 60%;
      display: inline-block;
      border-radius: 4px;

      white-space: nowrap;
      text-overflow: ellipsis;

    }
    // :hover {
    //     color: #0077ff;
    //     text-decoration: none;
    //   }
    .number{
      height: 100%;
      // width: 100%;
      font-size: 28px;
      color: #830707;
      background-color: #e0e0e0;
      width: 40%;
      display: inline-block;
      border-radius: 4px;


    }

  }
}

.data-item1{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  padding-bottom: 30px;
  text-align: center;
  // :hover {
  //         background-color: #8dc0bb;
  //         text-decoration: none;
  //       }
  .item1 {
    border-radius: 4px;
    padding-top: 10px;
    padding-left: 10px;
    margin-top: 10px;
    margin-left: 10px;
    width: 15%;
    height: 80px;
    flex-wrap: nowrap;


    // background-color: #17B3A3;

    .title1{
      font-size: 24px;
      color: rgb(0, 0, 0);
      padding-bottom: 10px;

    }
    :hover {
      color: #0077ff;
      text-decoration: none;
    }
    .number1{
      // height: 100%;
      width: 100%;
      font-size: 32px;
      color: #17B3A3;
      font-weight: bold;
    }
    .auxiliary1{
      font-size: 10px;
      padding-top: 10px;
      color: #17B3A3;
    }

  }
  // .span1 {
  //   padding:12px 6px 50px 6px;
  //   margin-left: 6px;
  //   border-left: 1px solid;
  //   font-size: 0;
  // }
}



.data-item2{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  padding-bottom: 30px;
  text-align: center;
  // :hover {
  //         background-color: #8dc0bb;
  //         text-decoration: none;
  //       }
  .item2 {
    border-radius: 4px;
    padding-top: 10px;
    padding-left: 10px;
    margin-top: 10px;
    margin-left: 10px;
    width: 15%;
    height: 80px;
    flex-wrap: nowrap;
    // background-color: #17B3A3;

    .title2{
      font-size: 24px;
      color: rgb(0, 0, 0);
      padding-bottom: 10px;

    }
    :hover {
      color: #0077ff;
      text-decoration: none;
    }
    .number2{
      // height: 80%;
      width: 100%;
      font-size: 32px;
      color: rgb(0, 162, 255);
      font-weight: bold;
    }
    .auxiliary2{
      font-size: 10px;
      padding-top: 10px;
      color: rgb(0, 162, 255);
    }

  }
}

</style>
